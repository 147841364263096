angular.module('MyHippoProducer.Controllers').directive('discountsSection', function () {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: 'pages/policy-details/sections/discounts/discounts.html',
        controller: function ($scope, WorkflowUtil, PolicyService, WorkflowAdapter) {
            function toTitleCase(str) {
                // Used for the threat-level stying
                str = str.replace("_", ' ');
                return str.replace(
                    /\w\S*/g,
                    function(txt) {
                        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    }
                );
            }

            const smartHomeDiscountPageFields = WorkflowAdapter.getAllFieldsForSection('discount_page', 'smart_home_kit_discount_status_section') || [];
            // Get smart-home discount keys from workflow
            const smartHomeDiscountDisplayKeys = {
                'property_data.smart_home_kit_activated': 'Discount Applied',
                'property_data.smart_home_pro_monitoring_kit': 'Pro Monitoring - Discount Applied',
            };
            const bySmartHomeDiscountDisplay = (tile => Boolean(smartHomeDiscountDisplayKeys[tile.key]));
            const updateLabelField = (tile) => {
                if(smartHomeDiscountDisplayKeys[tile.key]) {
                    return Object.assign(tile, {label: smartHomeDiscountDisplayKeys[tile.key]});
                }
                return tile;
            };
            const byTileKeyAB = (a, b) => (a.key < b.key ? -1: 1);
            
            $scope.smartHomeDiscountFields = smartHomeDiscountPageFields
                .filter(bySmartHomeDiscountDisplay)
                .sort(byTileKeyAB)
                .map(updateLabelField);
            // Get the smart-home kit monitoring tiles from the workflow
            const smartHomeThreatCoverage = {
                'property_data.smart_home_state.active_discount.water': 'Water Monitoring',
                'property_data.smart_home_state.active_discount.fire': 'Fire Monitoring',
                'property_data.smart_home_state.active_discount.theft': 'Theft Monitoring',
            };
            const bySmartHomeThreatDisplay = (tile => Boolean(smartHomeThreatCoverage[tile.key]));
            const updateThreatLabelField = (tile) => {
                const newFieldLabel = smartHomeThreatCoverage[tile.key];
                const newFieldValue = toTitleCase(tile.display || ' ');
                return [newFieldLabel, newFieldValue];
            };
            
            $scope.smartHometTreatCoverages = smartHomeDiscountPageFields
                .filter(bySmartHomeThreatDisplay)
                .map(updateThreatLabelField);

            const smartHomeKitSummary = PolicyService.getSmartHome();
            // Get the smart-home provider from the smart-home API
            if(smartHomeKitSummary.kitsInfo && smartHomeKitSummary.kitsInfo.kit_details && smartHomeKitSummary.kitsInfo.kit_details[0]) {
                $scope.smartHomeKitProvider = toTitleCase(smartHomeKitSummary.kitsInfo.kit_details[0].provider);
            } else {
                const selectedKit = _.get(PolicyService.policyInfo, 'smart_home.ordering_info.selected_kit');
                if (selectedKit) {
                    $scope.smartHomeKitProvider = toTitleCase(selectedKit.split('_')[0]); // for cases such as simplisafe_pro -> simplisafe
                }
            }

            // Get the smart-home kits information from the smart-home API
            if(smartHomeKitSummary.kitsInfo && smartHomeKitSummary.kitsInfo.kit_details) {
                $scope.smartHomeKits = smartHomeKitSummary.kitsInfo.kit_details;
            }
        }
    };
});
